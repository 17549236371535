import { Controller } from "stimulus"

export default class extends Controller {

  connect() {
    // setTimeout(() => {
    //   $(this.element).select2({
    //     dropdownParent: $(this.element).parent() //$('#platform-modal')
    //   })
    // }, 0)
    
    $(this.element).select2({
      placeholder: this.element.dataset.placeholder,
      // dropdownParent: $(this.element).parent() //$('#platform-modal')
    })

    // console.log('done...')
  }

  disconnect() {
    $(this.element).select2("destroy")
  }

}
