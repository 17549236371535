import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "input" ]

  connect() {
    // console.log('here??')
  }

  onToggle(e) {
    if (this.inputTarget.type === "password") {
      this.inputTarget.type = "text";
    } else {
      this.inputTarget.type = "password";
    }
  }
}
