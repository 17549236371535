import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "buttonText" ]

  static values = {
    countdownFrom: { type: Number, default: 30 }
  }  

  connect() {
    // setTimeout(() => {
    //   $(this.element).select2({
    //     dropdownParent: $(this.element).parent() //$('#platform-modal')
    //   })
    // }, 0)

    this.element.setAttribute("disabled", "disabled")
    this.originalText = this.buttonTextTarget.innerText.trim()
    console.log("original text - ", this.originalText)

    let timer = this.countdownFromValue
    this.buttonTextTarget.innerText = `Retry in ${timer}s`

    this.disableInterval = setInterval(() => {
      timer--
      if (timer > 0) {
        this.buttonTextTarget.innerText = `Retry in ${timer}s`
      } else {
        this.buttonTextTarget.innerText = this.originalText
        this.element.removeAttribute("disabled")
        clearInterval(this.disableInterval)
      }
      
    }, 1000)
  }

  disconnect() {
    clearInterval(this.disableInterval)
  }
}
