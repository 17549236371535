import iziToast from "izitoast";
import { Controller } from "stimulus"
// import intlTelInput from 'intl-tel-input'

export default class extends Controller {
  static targets = ["form", "email"]

  connect() {
    // console.log('Hello, Stimulus!', this.panTarget, this.customerNameTarget)

    if (this.emailTarget.value !== "") {
      // we already know which session we're using by default 

     // this.checkAccount()
    }

    this.ajaxRunning = false;
  }

  checkAccount(e) {
    e.preventDefault()
    let beforeAjaxEvent = new Event('ajax:before')
    let afterAjaxEvent = new Event('ajax:complete')

    document.dispatchEvent(beforeAjaxEvent)
    
    $.ajax({
      type: 'POST',
      url: '/sessions/check_account',
      dataType: 'script',
      data: {
        email: this.emailTarget.value,
        user_jwt: (new URLSearchParams(window.location.search)).get('user_jwt'),
      },
      success: (response) => {
        document.dispatchEvent(afterAjaxEvent)
      },
      error: (response) => {
        console.error('Something went wrong.')
        document.dispatchEvent(afterAjaxEvent)
      }
    });
  }

  submitForm(e){
    e.preventDefault()
    this.getFormData()
    let formData = this.getFormData()
    let url = new URL(`${this.formTarget.action}`);
    let home_url = new URL(`${window.location.protocol}//${window.location.host}/platforms`)
    let encodedCredentials = btoa(`${formData.email}:${formData.password}`)

    const headers = {
      Authorization: `Basic ${encodedCredentials}`
    }

    let options = {
      method: 'POST',
      headers: headers,
      body: new URLSearchParams(formData)
    }
   
    if(this.validateForm(formData)){
        fetch(url, options).then((response) =>{
          switch(response.status){

              case 200:
                console.log("Redirecting...")
                home_url.username = formData.email 
                home_url.password = formData.password
               window.location.href = home_url
              break;

              case  401:
                iziToast.error({title: 'Error', message: 'Email or password incorrect', position: 'topCenter'})
              break;

              default:
                alert(`Error ${response.status}`)
              break;
          }

          if(response.status === 401)
          {
            
          }
         
        })
    }else{
      alert("Email or password not entered")
    }
    
    
  }

  getFormData(){
    let formData = new Object()
    let formDataItems = this.formTarget.elements
    for(let i = 0; i < formDataItems.length; i++){
      formData[formDataItems[i].name] = formDataItems[i].value
    }
     return formData;
  }

  validateForm(formData){
    if(formData.email == "" || formData.password == ""){
      return false;
    }
    return true;
  }
}