import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "switch", "settingControl", "settlementScheduleTime"]

  connect() {
    console.log("connected!", this.element)
  }

  disconnect() {
  }

  onSwitchChanged(e) {
    for (let target of this.settingControlTargets) {
      if (e.currentTarget.checked) {
        target.removeAttribute('disabled')
      } else {
        target.setAttribute('disabled', 'disabled')
      }
    }
  }

  onSettlementFrequencyChanged(e) {
    if (e.currentTarget.value == "immediately") {
      this.settlementScheduleTimeTarget.setAttribute('disabled', 'disabled')
    } else {
      this.settlementScheduleTimeTarget.removeAttribute('disabled')
    }
  }
}
