import { Controller } from "stimulus"
import { create, destroy, registerPlugin } from 'filepond';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';

registerPlugin(FilePondPluginFileValidateType);

export default class extends Controller {
  static targets = [ "fileForm", "file", "progress", "fileIdInput", "fileError", "attachFileContainer" ]

  connect() {
    console.log(this.fileTarget, $(this.fileFormTarget).find('[name="authenticity_token"]').val())

    for (let target of this.fileTargets) {
      console.log("creating..")
      let pond = create(target, {
        server: {
          url: this.fileFormTarget.action,
          process: {
            url: "",
            headers: {
              'X-CSRF-Token': $(this.fileFormTarget).find('[name="authenticity_token"]').val()
            }
          }
        },
        checkValidity: true,
      })

      pond.on("processfileprogress", (e, progress) => {
        this.progressTarget.style.width = `${parseInt(progress * 100)}%`
      })

      pond.on("processfile", (e, f) => {
        console.log('processed!!', e, f.filename, f.serverId, f)
        if (e) {
          this.fileErrorTarget.classList.remove("no-display")
        } else {
          this.fileIdInputTarget.value = f.serverId
          this.attachFileContainerTarget.classList.remove("no-display")
        }
      })
    }
  }

  onProgress(e) {

  }

  disconnect() {
    for (let target of this.fileTargets) {
      destroy(target)
    }
  }
}
