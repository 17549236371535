import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "pspMatchingRules", "pspAdvancedSettings", "field"]

  connect() {
    console.log("connected!", this.element)

    if (this.element.dataset.currentPlatformType) {
      this._doPlatformTypeChanged(this.element.dataset.currentPlatformType)
    }
  }

  disconnect() {
  }

  onPlatformTypeChanged(e) {
    console.log(e.currentTarget.value)

    this._doPlatformTypeChanged(e.currentTarget.value)

    // if (e.currentTarget.value == "payment_services_provider") {
    //   this.pspMatchingRulesTarget.classList.remove("no-display")
    //   this.pspAdvancedSettingsTarget.classList.remove("no-display")

    //   this.pspMatchingRulesTarget.setAttribute('disabled')
    //   for (let target of this.fieldTargets) {
    //     target.removeAttribute('disabled')
    //     target.setAttribute('required', 'required')
    //   }
    // } else {
    //   this.pspMatchingRulesTarget.setAttribute('disabled', 'disabled')
    //   this.pspMatchingRulesTarget.classList.add("no-display")
    //   this.pspAdvancedSettingsTarget.classList.add("no-display")
    //   for (let target of this.fieldTargets) {
    //     target.setAttribute('disabled', 'disabled')
    //     target.removeAttribute('required')
    //   }
    // }
  }

  _doPlatformTypeChanged(type) {
    if (type == "payment_services_provider") {
      this.pspMatchingRulesTarget.classList.remove("no-display")
      this.pspAdvancedSettingsTarget.classList.remove("no-display")

      this.pspMatchingRulesTarget.setAttribute('disabled', 'disabled')
      for (let target of this.fieldTargets) {
        target.removeAttribute('disabled')
        // target.setAttribute('required', 'required')
      }
    } else {
      this.pspMatchingRulesTarget.setAttribute('disabled', 'disabled')
      this.pspMatchingRulesTarget.classList.add("no-display")
      this.pspAdvancedSettingsTarget.classList.add("no-display")
      for (let target of this.fieldTargets) {
        target.setAttribute('disabled', 'disabled')
        // target.removeAttribute('required')
      }
    }
  }

}
