import { Controller } from "stimulus"

export default class extends Controller {

  connect() {
  }

  onClick() {
    // console.log('clicked!')
    if (this.element.dataset.turboFrame) {
      // console.log('framed!')
      document.getElementById(this.element.dataset.turboFrame).innerHTML = `
        <div class="d-flex justify-content-center">
          <div class="spinner-grow text-secondary m-5" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
      `
    }
  }

  disconnect() {
  }
}
