import { Controller } from "stimulus"
import Pinlogin from "pinlogin"
import {create, get, supported} from "@github/webauthn-json"

import 'pinlogin/dist/pinlogin.css'

export default class extends Controller {
  static targets = [ "loginPin", "userIdentifier" ]

  connect() {
    // console.log('Hello, Stimulus!', this.panTarget, this.customerNameTarget)
    let pinlogin = new Pinlogin(document.querySelector('#loginpin'), {
      fields: 6,
      complete : pin => {
        // console.log('Awesome! You entered: ' + pin);
        let userJwt = this.loginPinTarget.dataset.newJwtToken ? this.loginPinTarget.dataset.newJwtToken : (new URLSearchParams(window.location.search)).get('user_jwt');
        let utmSource = (new URLSearchParams(window.location.search)).get('utm_source');
        // let orderId = (new URLSearchParams(window.location.search)).get('order_id');
        // let newSub = (new URLSearchParams(window.location.search)).get('new_subscription');
        // let offerInvoice = (new URLSearchParams(window.location.search)).get('offer_invoice');
        // let subVariant = (new URLSearchParams(window.location.search)).get('variant_id');

        pinlogin.disable();

        $.ajax({
          type: 'POST',
          url: '/sessions/verify_code',
          dataType: 'script',
          data: {
            pin: pin,
            user_jwt: userJwt,
            utm_source: utmSource,
            // order_id: orderId,
            webauthn_supported: supported(),
            // new_subscription: newSub,
            // offer_invoice: offerInvoice,
            // variant_id: subVariant,
          },
          success: function (response) {
            pinlogin.enable();
          },
          error: function (response) {
            pinlogin.enable();
            console.error('Something went wrong.');
          }
        });
      }
    });
  }

  startWebAuthnRegistration(e) {
    // let orderId = (new URLSearchParams(window.location.search)).get('order_id')
    // let newSub = (new URLSearchParams(window.location.search)).get('new_subscription')
    // let subVariant = (new URLSearchParams(window.location.search)).get('variant_id')
    let userJwt = this.loginPinTarget.dataset.newJwtToken ? this.loginPinTarget.dataset.newJwtToken : (new URLSearchParams(window.location.search)).get('user_jwt');
    let userIdentifier = this.userIdentifierTarget.dataset.userIdentifier
    let sessionJwt = null

    let beforeAjaxEvent = new Event('ajax:before')
    let afterAjaxEvent = new Event('ajax:complete')

    document.dispatchEvent(beforeAjaxEvent)
    
    $.ajax({
      type: 'GET',
      url: '/api/webauthn/credential/init',
      dataType: 'json',
      data: {
        user_identifier: userIdentifier
      },
      success: (initResponse) => {
        console.log(initResponse.options)
        // document.dispatchEvent(afterAjaxEvent)

        sessionJwt = initResponse.sessionJwt

        create({ "publicKey": initResponse.options })
        .then(credential => {
          console.log('credential created!', credential)
          
          // Now send the credential we've gotten back to the server for verification and storage
          $.ajax({
            type: 'POST',
            url: '/api/webauthn/credential/verify',
            dataType: 'json',
            data: {
              credential: credential,
              user_jwt: userJwt,
              session_jwt: sessionJwt,
              // order_id: orderId,
              // new_subscription: newSub,
              // variant_id: subVariant,
            },
            success: (verifyResponse) => {
              // credential has been saved and user has been logged in
              console.log(verifyResponse)
              document.dispatchEvent(afterAjaxEvent)

              // Using turbolnks, visit the post login url provided
              Turbolinks.visit(verifyResponse.post_verify_url)
            },
            error: (verifyResponse) => {
              console.error('Something went wrong.')
              document.dispatchEvent(afterAjaxEvent)

              // ideally we should have a different error message here but we'll be lazy for now
              alert("There was an issue with your devices's built-in authentication method. We will proceed on the basis of the PIN you gave us.")
              $(".modal-state:checked").prop("checked", false).change()
              Turbolinks.visit(window.webauthnCredentialInitFailureFallbackUrl)
            }
          });
        }).catch(error => {
          // something went wrong, we need to fail gracefully...
          console.log('error!', error)
          document.dispatchEvent(afterAjaxEvent)

          console.log('about to go to ', window.webauthnCredentialInitFailureFallbackUrl);

          alert("There was an issue with your devices's built-in authentication method. We will proceed on the basis of the PIN you gave us.")
          $(".modal-state:checked").prop("checked", false).change()
          Turbolinks.visit(window.webauthnCredentialInitFailureFallbackUrl)
        })
      },
      error: (initResponse) => {
        console.error('Something went wrong.')
        document.dispatchEvent(afterAjaxEvent)

        alert("There was an issue with your devices's built-in authentication method. We will proceed on the basis of the PIN you gave us.")
        $(".modal-state:checked").prop("checked", false).change()
        Turbolinks.visit(window.webauthnCredentialInitFailureFallbackUrl)
      }
    });
  }
}