import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "submitButton" ]

  connect() {
    
  }

  onSubmit(e) {
    this.submitButtonTarget.click()
    $('.dropdown-toggle').dropdown('hide');
  }
}
