import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "presetName" ]

  connect() {
    console.log("connected!", this.element)
  }

  disconnect() {
  }

  onSaveAsPresetChanged(e) {
    if (e.currentTarget.checked) {
      this.presetNameTarget.removeAttribute('disabled')
      this.presetNameTarget.classList.remove('no-display')
      this.presetNameTarget.setAttribute('required', 'required')
    } else {
      this.presetNameTarget.setAttribute('disabled', 'disabled')
      this.presetNameTarget.classList.add('no-display')
      this.presetNameTarget.removeAttribute('required')
    }
  }
}
