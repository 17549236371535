import { Controller } from "stimulus"
// import intlTelInput from 'intl-tel-input'

export default class extends Controller {
  static targets = [ "selectedSpreadsheetsContainer", "selectFiles" ]

  connect() {
    console.log('Hello, Stimulus!', this.selectFilesTarget.dataset)

    this.permissionsScope = ['https://www.googleapis.com/auth/drive.file']

    this.oauthToken = null;
    this.pickerReady = false
  }

  initializePicker() {
    console.log('we need to initialize the picker now!');

    gapi.load('auth', {'callback': (() => { this.onAuthApiLoad() }) })
    gapi.load('picker', {'callback': (() => { this.onPickerApiLoad() }) })
  }

  selectFilesClicked(e) {
    e.preventDefault();

    console.log('selectFiles clicked!')

    window.gapi.auth.authorize(
      {
        'client_id': this.selectFilesTarget.dataset.googleClientId,
        'scope': this.permissionsScope,
        'immediate': false
      }, ((authResult) => { this.handleAuthResult(authResult) }) );

  }

  onAuthApiLoad() {
    this.selectFilesTarget.removeAttribute("disabled")
  }

  onPickerApiLoad() {
    this.pickerReady = true
  }

  handleAuthResult(authResult) {
    console.log(authResult)
    
    if (authResult && !authResult.error) {
      this.oauthToken = authResult.access_token;
      this.createPicker();
    }
  }

  createPicker() {
     if (this.pickerReady && this.oauthToken) {

      let picker = new google.picker.PickerBuilder()
          .enableFeature(google.picker.Feature.NAV_HIDDEN)
          .enableFeature(google.picker.Feature.MULTISELECT_ENABLED)
          .setAppId(this.selectFilesTarget.dataset.googleProjectNumber)
          .setOAuthToken(this.oauthToken)
          .addView(google.picker.ViewId.SPREADSHEETS)
          .addView(new google.picker.DocsUploadView())
          .setDeveloperKey(this.selectFilesTarget.dataset.pickerApiKey)
          .setCallback(((data) => { this.onItemsPicked(data) }))
          .build();
       picker.setVisible(true);

      let elements= document.getElementsByClassName('picker-dialog');
      for (var i = 0; i < elements.length; i++ ) {
        elements[i].style.zIndex = '2000';
      }
    }
  }

  removeSpreadsheetClicked(e) {
    console.log(e.currentTarget)

    e.currentTarget.parentNode.remove();
  }

  onItemsPicked(data) {
    console.log(data);

    if (data.action == google.picker.Action.PICKED) {

      for (let file of data.docs) {
        console.log(file)

        this.selectedSpreadsheetsContainerTarget.innerHTML += `
          <li class="list-group-item d-flex justify-content-between align-items-center">
            ${file.name}
            <input type="hidden" name="merchant_integration[content_settings][spreadsheets][][id]" value="${file.id}">
            <input type="hidden" name="merchant_integration[content_settings][spreadsheets][][name]" value="${file.name}">
            <button type="button" class="btn btn-icon btn-sm btn-danger" data-action="google--picker#removeSpreadsheetClicked">
              <i class="fas fa-trash"></i>
            </button>
          </li>`
      }
    }
  }
}