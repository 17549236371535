import { Controller } from "stimulus"
import { create, destroy, registerPlugin } from 'filepond';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';

registerPlugin(FilePondPluginFileValidateType);

export default class extends Controller {
  static targets = [ "fileForm", "file", "progress", "fileIdInput", "fileError", "attachFileContainer", "target", "template", "wrapper" ]
  static values = {
    wrapperSelector: {
      type: String,
      default: '.file-wrapper'
    }
  }

  connect() {
    // console.log('connected', this.progressTarget);

    // console.log(this.fileTarget, $(this.fileFormTarget).find('[name="authenticity_token"]').val())

    // for (let target of this.fileTargets) {
    //   console.log("creating..")
    //   let pond = create(target, {
    //     server: {
    //       url: this.fileFormTarget.action,
    //       process: {
    //         url: "",
    //         headers: {
    //           'X-CSRF-Token': $(this.fileFormTarget).find('[name="authenticity_token"]').val()
    //         }
    //       }
    //     },
    //     checkValidity: true,
    //   })

    //   pond.on("processfileprogress", (e, progress) => {
    //     this.progressTarget.style.width = `${parseInt(progress * 100)}%`
    //   })

    //   pond.on("processfile", (e, f) => {
    //     console.log('processed!!', e, f.filename, f.serverId, f)
    //     if (e) {
    //       this.fileErrorTarget.classList.remove("no-display")
    //     } else {
    //       // this.fileIdInputTarget.value = f.serverId
    //       this.addFileToList(f)
    //       this.attachFileContainerTarget.classList.remove("no-display")
    //     }
    //   })
    // }
  }

  onProgress(e) {

  }

  addFileToList(f) {
    console.log(f, f.fileType, f.fileSize)

    let content = this.templateTarget.innerHTML.replace(/FILE_NAME/g, f.filename)

    window.mixpanel.track("Bridge - File Uploaded", {
      file_type: f.fileType,
      file_size: f.fileSize
    })

    // if (e.currentTarget.dataset.replaceableValue && e.currentTarget.dataset.replacementText) {
    //   const replaceableRegex = new RegExp(e.currentTarget.dataset.replaceableValue, "g")
    //   content = content.replace(replaceableRegex, e.currentTarget.dataset.replacementText)
    // }

    // if (e.currentTarget.dataset.transactionType == "COGS") {
    //   content = content.replace(/Select An Account/g, "Select A COGS Expense Account")
    // }

    // console.log("we have num wrappers", this.wrapperTargets.length)
    content = content.replace(/FILE_ID/g, f.serverId)

    this.targetTarget.insertAdjacentHTML('beforeend', content)
  }

  removeFileFromList(e) {
    e.preventDefault()
    window.mixpanel.track("Bridge - File Removed")
    
    const wrapper = e.target.closest(this.wrapperSelectorValue)

    // if (wrapper.dataset.newRecord === 'true') {
    wrapper.remove()
  }

  wrapperTargetDisconnected(element) {
    if (this.wrapperTargets.length === 0) {
      this.attachFileContainerTarget.classList.add("no-display")
    }
  }

  fileTargetConnected(target) {
    let pond = create(target, {
      server: {
        url: this.fileFormTarget.action,
        process: {
          url: "",
          headers: {
            'X-CSRF-Token': $(this.fileFormTarget).find('[name="authenticity_token"]').val()
          }
        }
      },
      checkValidity: true,
    })

    pond.on("processfileprogress", (e, progress) => {
      this.progressTarget.style.width = `${parseInt(progress * 100)}%`
    })

    pond.on("processfile", (e, f) => {
      console.log('processed!!', e, f.filename, f.serverId, f)
      if (e) {
        this.fileErrorTarget.classList.remove("no-display")
      } else {
        // this.fileIdInputTarget.value = f.serverId
        this.addFileToList(f)
        this.attachFileContainerTarget.classList.remove("no-display")
      }
    })
  }

  disconnect() {
    for (let target of this.fileTargets) {
      destroy(target)
    }
  }
}
