import { Controller } from "stimulus"

export default class extends Controller {

  static targets = [ "select" ]

  connect() {
    setTimeout(() => {

      for (let target of this.selectTargets) {
        $(target).select2({
          placeholder: target.dataset.placeholder,
          dropdownParent: $(this.element).parent() //$('#platform-modal')
        })
      }
    }, 0)

    this.selectedOptions = {}
    
    // $(this.element).select2({
    //   dropdownParent: $(this.element).parent() //$('#platform-modal')
    // })
    // console.log('done...')
  }

  onColumnSelecting(e) {
    // console.log("selecting", $(e.currentTarget).select2('data'), e.currentTarget.id)
    this.selectedOptions[e.currentTarget.id] = $(e.currentTarget).select2('data')
  }

  onColumnSelected(e) {
    // console.log("old", this.selectedOptions[e.currentTarget.id])
    // console.log("new", $(e.currentTarget).select2("data"))

    let oldItems = this.selectedOptions[e.currentTarget.id].map(i => i.id)
    let newItems = $(e.currentTarget).select2("data").map(i => i.id)

    let newItem = newItems.filter(i => !oldItems.includes(i))

    // console.log("newItem", newItem)

    this.handleSelectedValue(e.currentTarget.id, newItem[0])
  }

  onColumnUnselecting(e) {
    // console.log("unselecting", $(e.currentTarget).select2('data'))
    this.selectedOptions[e.currentTarget.id] = $(e.currentTarget).select2('data')
  }

  onColumnUnselected(e) {
    // console.log("old", this.selectedOptions[e.currentTarget.id])
    // console.log("new", $(e.currentTarget).select2("data"))

    let oldItems = this.selectedOptions[e.currentTarget.id].map(i => i.id)
    let newItems = $(e.currentTarget).select2("data").map(i => i.id)

    let removedItem = oldItems.filter(i => !newItems.includes(i))
    // console.log("removedItem", removedItem)

    this.handleUnselectedValue(e.currentTarget.id, removedItem[0])
  }

  handleSelectedValue(subjectId, value) {
    for (let target of this.selectTargets) {
      // console.log(`idField for ${target.id} is`, target.dataset.idField)

      if (target.id != subjectId && !(target.dataset.idField == "true")) {
        // remove the option from the list of available options

        // console.log("removing!", target.id, e.currentTarget.value)
        target.querySelector('option[value="'+value.replace(/["\\]/g, '\\$&')+'"]').disabled = true;
      }
    }
  }

  handleUnselectedValue(subjectId, value) {
    for (let target of this.selectTargets) {
      // console.log(`idField for ${target.id} is`, target.dataset.idField)

      if (target.id != subjectId && !(target.dataset.idField == "true")) {
        // remove the option from the list of available options

        // console.log("removing!", target.id, e.currentTarget.value)
        target.querySelector('option[value="'+value.replace(/["\\]/g, '\\$&')+'"]').disabled = false;
      }
    }
  }

  disconnect() {
    for (let target of this.selectTargets) {
      $(target).select2("destroy")
    }
  }

}
