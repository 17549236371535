import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "collapsible" ]

  connect() {
    // console.log('here??')
    // $(this.element).collapse()
  }

  onToggle(e) {
    e.currentTarget.classList.toggle('active')
    $(this.collapsibleTarget).collapse('toggle')
  }

  disconnect() {
    // $(this.collapsibleTarget).collapse('dispose')
  }
}
