import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    
  }

  onChange(e) {
    let nextUrl = e.currentTarget.querySelector('option[value="'+e.currentTarget.value.replace(/["\\]/g, '\\$&')+'"]').dataset.selectedUri
    // console.log("nexturl is", nextUrl)
    let turboFrame = document.querySelector(`#${this.element.dataset.turboFrame}`)
    turboFrame.src = nextUrl
    // Turbo.visit(nextUrl)
  }

  disconnect() {
    // $(this.collapsibleTarget).collapse('dispose')
  }
}
