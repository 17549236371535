import { Controller } from "stimulus"
import Clipboard from 'clipboard'

export default class extends Controller {
  static targets = [ "email" ]

  connect() {
    // console.log('Hello, Stimulus!', this.element)
    let config = {}

    if (this.element.dataset.clipboardContainer) {
      config.container = document.querySelector(this.element.dataset.clipboardContainer)
    }

    this.clipboard = new Clipboard(this.element, config);

    let element = this.element;

    this.clipboard.on('success', function(e) {
      console.info('Action:', e.action)
      console.info('Text:', e.text)
      console.info('Trigger:', e.trigger)
      
      element.title = "Copied :)"
      $(element).tooltip({ trigger: 'manual' })
      $(element).tooltip('show')

      setTimeout(() => {
        $(element).tooltip('hide')        
      }, 700)

      e.clearSelection()
    })

    this.clipboard.on('error', function(e) {
      console.error('Action:', e.action);
      console.error('Trigger:', e.trigger);
    });
  }

  disconnect() {
    this.clipboard.destroy()
  }
}