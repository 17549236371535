// import { connectToParent } from 'penpal';
import Cookies from 'js-cookie';

export default class AuthPopup {
  constructor() {
    if (window.opener !== null) {
      document.addEventListener('click', event => {
        console.log('got a click!', event);

        Cookies.remove('connect_to');
        Cookies.remove('mansa_oauth_popup');
        
        if (event.target.closest('.sizzle-close, #close-co, .mansa-close')) {
          event.preventDefault();

          console.log('trying to close..');

          this.closePopup();
        }
      }, false);

      window.addEventListener('beforeunload', e => {
        // Do something
        Cookies.remove('connect_to');
        Cookies.remove('mansa_oauth_popup');

        window.opener.postMessage(JSON.stringify({
          eventName: 'windowClosedManually',
          args: {
            redirectUri: window.redirectUri
          }
        }), '*');
      }, false);
    }
  }

  // inIframe() {
  //   try {
  //     return window.self !== window.top;
  //   } catch (e) {
  //     return true;
  //   }
  // }

  closePopup() {
    if (window.opener !== null) {
      Cookies.remove('connect_to');
      Cookies.remove('mansa_oauth_popup');

      window.opener.postMessage(JSON.stringify({
        eventName: 'requestWindowClose',
        args: {
          redirectUri: window.redirectUri
        }
      }), '*');
    }
  }

  setConnectToPlatform(platform) {
    Cookies.set('connect_to', platform, { expires: 3 });
  }

  // persistUserSession(jwtToken) {
  //   if (this.inIframe()) {
  //     this._connectionToParent.promise.then(parent => {
  //       parent.authenticateUser(jwtToken);
  //     });
  //   }
  // }
}
